import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  loggedInUser: {
    _id: '',
    name: '',
    username:'',
    is_super: false,
    role: '',
  }
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'assign/loggedInUser':
      return {...state, ...rest}
    default:
      return state
  }
}

const store = createStore(changeState)
export default store